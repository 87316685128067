import Vue from 'vue';
import axios from 'axios';
import store from '@/store/store';

Vue.use(axios);

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
});

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_API,
});

const dashboardApi = axios.create({
  baseURL: process.env.VUE_APP_DASHBOARD_API,
});

const usaApi = axios.create({
  baseURL: process.env.VUE_APP_USA_FOOTBALL_API,
});

api.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    if (
      (response.status != undefined && response.status === 200) ||
      response.status === 201
    ) {
      return response;
    }
  },
  (error) => {
    let res = error.response || null;
    if (res != null && res.status == 401) {
      store.dispatch('user/singOut');
      window.location.href = '/user/login';
    }
    return Promise.reject(res);
  },
);

usaApi.interceptors.request.use(
  (config) => {
    config.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { api, baseApi, dashboardApi, usaApi };
